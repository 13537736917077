import React from "react"
import {graphql, Link, useStaticQuery} from "gatsby"


const query = graphql`
  {
    allStrapiBlog(sort: {fields: published_at, order: DESC}
     filter: {category: {slug: {in: ["blog", "domains"]}}}
      limit: 3
     ){
      nodes  {
          id
          title
          published_at(formatString: "D MMMM, YYYY", locale: "en")
          image {
            childImageSharp {
               fixed {
                  src
               }
            }
          }
          slug
          category {
            title
            slug
          }
        }
      }
  }
`

const BlogSidebar = () => {
    const data = useStaticQuery(query)
    const blogPosts = data.allStrapiBlog.nodes

    const cPanelPosts = []
    const domainsPosts = []
    const generalPosts = []


    for (let post of blogPosts) {
        if (post.category.slug === "cpanel-en") {
            cPanelPosts.push(post)
        } else if (post.category.slug === "blog") {
            generalPosts.push(post)
        } else if (post.category.slug === "domains") {
            domainsPosts.push(post)
        }
    }


    return (
        <div className="widget-area">
            <div className="widget widget_search">
                <h3 className="widget-title">Search article</h3>

                <form className="search-form">
                    <label>
                        <span className="screen-reader-text">Search for:</span>
                        <input type="search" className="search-field" placeholder="Search ..."/>
                    </label>
                    <button type="submit">
                        <i className="bx bx-search-alt"/>
                    </button>
                </form>
            </div>

            <div className="widget widget_tracer_posts_thumb">
                <h3 className="widget-title">Recent</h3>
                {
                    blogPosts.map(data => {
                        return (
                            <article className="item" key={data.id}>
                                <Link to={'/' + data.slug + '/'} className="thumb">
                                    <img className="fullimage cover" src={data.image.childImageSharp.fixed.src}
                                         alt='Blog Tumbs'/>
                                </Link>
                                <div className="info">
                                    <span>{data.published_at}</span>
                                    <h4 className="title usmall">
                                        <Link to={'/' + data.slug + '/'}>
                                            {data.title}
                                        </Link>
                                    </h4>
                                </div>
                                <div className="clear"/>
                            </article>
                        )
                    })
                }
            </div>

            <div className="widget widget_categories">
                <h3 className="widget-title">Categories</h3>

                <ul>
                    <li>
                        <Link to="/category/cpanel-en/">
                            cPanel <span className="post-count">({cPanelPosts.length})</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/category/blog/">
                            Blog <span className="post-count">({generalPosts.length})</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/category/domains/">
                            Domains <span className="post-count">({domainsPosts.length})</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}


export default BlogSidebar
