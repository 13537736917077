import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import BlogSidebar from "../components/BlogContent/BlogSidebar"
import Footer from "../components/App/Footer"
import { Markup } from "react-render-markup"

const ComponentName = ({ data }) => {
  const { title, content, author, image, updated_at, category } = data.blog
  return (
    <Layout>
      <Navbar />
      <div className="page-title-area">
        <div className="container">
          <div className="page-title-content text-left">
            <h2 className="mb-4 h2-title">{title}</h2>
          </div>
          <section className="blog-details-area pt-100">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-12">
                  <div className="blog-details-desc">
                    <section className="blog-details-area">
                      <div className="container">
                        <div className="row">
                          <div className="col-12">
                            <div className="blog-details-desc">
                              <div className="article-image">
                                <img src={image.childImageSharp.fixed.src} alt="Blog Cover" loading="lazy" />
                              </div>

                              <div className="article-content">
                                <div className="entry-meta mb-4">
                                  <ul>
                                    <li>
                                      <i className="bx bx-folder-open" />
                                      <span>Category</span>
                                      <Link to={`/category/${category.slug}/`}>
                                        {category.name}
                                      </Link>
                                    </li>
                                    <li>
                                      <i className="bx bx-user" />
                                      <span>Author</span>
                                      <Link>
                                        {author}
                                      </Link>
                                    </li>
                                    <li>
                                      <i className="bx bx-calendar" />
                                      <span>Last Update</span>
                                      <Link>
                                        {updated_at}
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                                <Markup markup={content} />
                                {/*<div>{content}</div>*/}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <BlogSidebar />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query GetSingleBlog($slug: String) {
    blog: strapiBlog(slug: { eq: $slug }) {
        author
        title
        updated_at(formatString: "D MMMM, YYYY", locale: "en")
        image {
          childImageSharp {
            fixed {
              src
            }
          }
        }
        category {
          name
          slug
        }
        content
    }
  }
`

export default ComponentName
